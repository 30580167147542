<template>
  <div>
    <app-breadcrumb />
    <b-card
      title="Layanan"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nama Layanan"
            label-for="vertikal"
          >
            <validation-provider
              #default="{ errors }"
              name="vertikal"
            >
              <v-select
                v-model="selectedVertikal"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="vertikal"
                placeholder="--- Pilih Layanan ---"
                @option:selected="getLayanan()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="itemsLayanan"
        striped
        hover
        responsive
        :fields="tabColumns"
      >
        <template #cell(kode_layanan)="data">
          {{ data.value }}
        </template>
        <template #cell(nama_layanan)="data">
          {{ data.value }}
        </template>
        <template #cell(deskripsi)="data">
          {{ data.value }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import 'vue-select/dist/vue-select.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BTable,
} from 'bootstrap-vue'
import { db } from '@/firebase'

export default {
  components: {
    AppBreadcrumb,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      tabColumns: [
        { key: 'kode_layanan', label: 'Kode Layanan', sortable: false },
        { key: 'nama_layanan', label: 'Jenis Layanan', sortable: false },
        { key: 'deskripsi', label: 'Deskripsi Layanan', sortable: false },
      ],
      selectedVertikal: '',
      tableLayanan: [],
      itemsLayanan: [],
      vertikal: [],
    }
  },
  created() {
    this.getVertikal()
  },
  methods: {
    getVertikal() {
      db.collection('layanan').get().then(snapshot => {
        const arr = []
        snapshot.forEach(doc => {
          arr.push({
            value: doc.id,
            text: doc.data().nama_vertikal,
          })
        })
        this.vertikal = [...new Set(arr)]
      })
    },
    getLayanan() {
      const query = db.collection('layanan').doc(this.selectedVertikal.value).collection('layanan')
      query.get().then(snapshot => {
        const arr = []
        snapshot.docs.forEach(doc => {
          arr.push(doc.data())
        })
        this.itemsLayanan = [...new Set(arr)]
      })
    },
  },
}
</script>

<style>

</style>
